import {isRight} from 'fp-ts/lib/Either'
import {Type} from 'io-ts'
import {SetStateAction, useCallback, useEffect, useState} from 'react'

const parseStorage = (key: string, session: boolean): unknown | undefined => {
  const storage = session ? sessionStorage : localStorage
  const raw = storage.getItem(key)
  if (raw) {
    try {
      return JSON.parse(raw)
    } catch (e) {
      storage.removeItem(key)
      console.warn(
        `Invalid JSON in ${session ? 'sessionStorage' : 'localStorage'} ${key}`,
        key,
        raw
      )
    }
  }
  return undefined
}

// works just like useState, but persists the value to localstorage/sessionStorage too
// the codec validates the data on read to avoid corruption
export const usePersistedState = <T>(
  initialValue: T,
  key: string,
  codec: Type<T, T, unknown>,
  session = false
): [T, (v: SetStateAction<T>) => void] => {
  const decode = useCallback(
    (u: unknown) => {
      const result = codec.decode(u)

      if (isRight(result)) {
        return result.right
      }

      return initialValue
    },
    // initial value or codec change should not trigger re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const [value, setValue] = useState<T>(
    decode(parseStorage(key, session) ?? initialValue)
  )

  useEffect(() => {
    setValue(decode(parseStorage(key, session) ?? initialValue))
    // initial value change should not trigger re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, session, decode])

  useEffect(() => {
    const storage = session ? sessionStorage : localStorage
    if (typeof value !== 'undefined') {
      storage.setItem(key, JSON.stringify(value))
    } else {
      storage.removeItem(key)
    }
  }, [key, value, session])

  return [value, setValue]
}
