import React from 'react'
import {Route, Routes} from 'react-router'
import styled from 'styled-components'
import {Loading} from './components/Loading'
import {WaitThenNavigate} from './components/WaitThenNavigate'
import {useAccount} from './hooks/useAccount'
import {Auth} from './pages/Auth'
import {Home} from './pages/Home'

const Account = React.lazy(() => import('./pages/Account'))

const Wrapper = styled.div`
  height: 100%;
  color: ${p => p.theme.colors.black};
`

const App = () => {
  // todo loading is done and account comes along 300ms later
  // thats a bug I need to fix, or explicity catch auth denied instead
  // of assuming loading = false and account = null is no auth
  const [account, {loading, refetch: reloadAccount}] = useAccount()

  return (
    <Wrapper>
      <Routes>
        <Route path="auth" element={<Auth reloadAuth={reloadAccount} />} />
        <Route
          path="account"
          element={
            <>
              {loading ? (
                <Loading />
              ) : account ? (
                <React.Suspense fallback={<Loading />}>
                  <Account account={account} onSave={reloadAccount} />
                </React.Suspense>
              ) : (
                <WaitThenNavigate to="/login" />
              )}
            </>
          }
        />
        <Route path="/*" element={<Home account={account} />} />
      </Routes>
    </Wrapper>
  )
}

export default App
