import {Link} from 'react-router-dom'
import styled from '../lib/styled-components'

export const LinkButton = styled(Link)`
  display: flex;
  background: none;
  border: none;
  padding: 0 4px;
  font-size: 24px;

  text-decoration: none;
  color: ${p => p.theme.colors.blue};
  &:hover,
  &:visited,
  &:active {
    color: ${p => p.theme.colors.blueLighter};
    text-decoration: none;
  }
`
