import React, {ReactNode} from 'react'
import {Link} from 'react-router-dom'
import styled from '../lib/styled-components'

const Title = styled(Link)`
  font-size: 32px;
  line-height: 100%;
  letter-spacing: 0.01em;
  text-decoration: none;
  text-align: center;
  color: inherit;
  left: 0;
  right: 0;
  font-family: ${({theme}) => theme.font.header};
  color: ${({theme}) => theme.colors.blue};
  &:hover,
  &:visited,
  &:active {
    color: ${({theme}) => theme.colors.blue};
    text-decoration: none;
  }
  margin-top: -5px;
  word-wrap: none;
`

const MenuLeft = styled.div`
  display: flex;
  min-width: 32px;
  max-width: 20vh;
  justify-content: flex-start;
`

const MenuRight = styled.div`
  display: flex;
  min-width: 32px;
  max-width: 20vh;
  justify-content: flex-end;
`

export const Wrapper = styled.header<{adminMode: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 60px;
  white-space: nowrap;

  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 1;
  background-color: ${({theme}) => theme.colors.white};
  border-bottom: ${({adminMode, theme}) =>
    adminMode ? `8px solid ${theme.colors.yellow}` : 'none'};

  padding: 0 20px;
  @media (max-width: ${({theme}) => theme.breakPoints.mobile}) {
    padding: 0 8px;
  }
`

const APP_NAME = process.env.REACT_APP_NAME ?? 'Truck map'

export const Header = ({
  menuLeft,
  menuRight,
  adminMode = false
}: {
  menuLeft?: ReactNode
  menuRight?: ReactNode
  adminMode?: boolean
}) => {
  return (
    <Wrapper adminMode={adminMode}>
      <MenuLeft>{menuLeft}</MenuLeft>
      <Title to="/">{APP_NAME}</Title>
      <MenuRight>{menuRight}</MenuRight>
    </Wrapper>
  )
}
