import {Message, PostResponse} from './types'

export type Handler = (response: PostResponse) => void
const subscribers: Set<Handler> = new Set()
const worker = new Worker(new URL('./api', import.meta.url))

export const post = (message: Message) => {
  worker.postMessage(message)
}

export const subscribe = (handler: Handler) => {
  subscribers.add(handler)
}

export const unSubscribe = (handler: Handler) => {
  subscribers.delete(handler)
}

worker.onmessage = (e: MessageEvent<PostResponse>) => {
  subscribers.forEach(handler => handler(e.data))
}
