import {FaEnvelope, FaTruck} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import styled from '../lib/styled-components'
import {Button} from './Button'

const APP_NAME = process.env.REACT_APP_NAME ?? 'Truck map'

const Wrapper = styled.div`
  .login {
    margin-top: 20px;
  }
`

const LoginButton = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 10px;
`

export const Register = () => {
  const navigate = useNavigate()
  return (
    <Wrapper>
      <p>
        {APP_NAME} is currently in beta. If you'd like to get early access we'd
        love to have you!
      </p>
      <LoginButton>
        <Button onClick={() => navigate('/register')}>
          Add your truck <FaTruck />
        </Button>
        <Button onClick={() => navigate('/login')}>
          Login <FaEnvelope />
        </Button>
      </LoginButton>
    </Wrapper>
  )
}
