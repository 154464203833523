import {ReactNode} from 'react'
import styled from '../lib/styled-components'

const Wrapper = styled.div`
  /* display: flex;
  flex-direction: column; */
  height: 100%;
  overflow: hidden;
`

// see Header.tsx
const Header = styled.div``

const Main = styled.main`
  /* flex: 1 1 auto; */

  margin-top: 60px;
  /* margin-bottom: 60px; */
  height: calc(100vh - 120px);
  max-height: fill-available;
`
const Footer = styled.footer`
  height: 60px;
  display: flex;
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: ${({theme}) => theme.colors.white};
  padding: 0 20px;
  @media (max-width: ${({theme}) => theme.breakPoints.mobile}) {
    padding: 0 8px;
  }
`

export const Layout = ({
  header,
  children,
  footer
}: {
  header: ReactNode
  children: ReactNode
  footer?: ReactNode
}) => {
  return (
    <Wrapper>
      <Header>{header}</Header>
      <Main>{children}</Main>
      <Footer>{footer}</Footer>
    </Wrapper>
  )
}
