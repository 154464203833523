import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'
import {useMemo} from 'react'
import {getWhenOptions} from '../../hooks/useWhen'
import styled from '../../lib/styled-components'

const Select = styled.select`
  text-align: center;
  max-width: 70vw;
  min-width: 50vw;
  font-family: ${props => props.theme.font.headerSecondary};
  font-size: 20px;
  padding: 5px;
  color: ${props => props.theme.colors.blue};
  &:focus {
    outline: ${({theme}) => theme.colors.blue};
  }
`

export const DaySelect = ({
  setWhen,
  when,
  onClick
}: {
  when: Date
  setWhen: (date: Date) => void
  onClick?: () => void
}) => {
  const [value, options] = useMemo(() => {
    const options = getWhenOptions()
    const found = options.find(
      ([, value]) => startOfDay(value) <= when && endOfDay(value) >= when
    )
    if (found) {
      return [found[1].toISOString(), options] as const
    }
    return [options[0][1].toISOString(), options] as const
  }, [when])

  return (
    <Select
      value={value}
      onChange={e => {
        setWhen(new Date(e.target.value))
      }}
      onClick={onClick}
    >
      {options.map(([label, value], i) => (
        <option key={i} value={value.toISOString()}>
          {label}
        </option>
      ))}
    </Select>
  )
}
