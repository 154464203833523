import {WHEN_PARAM} from '../hooks/useWhen'
import {WHERE_PARAM} from '../hooks/useWhere'

export const withQS = (append?: Record<string, string | undefined>) => {
  const sp = new URLSearchParams(document.location.search)
  const when = sp.get(WHEN_PARAM)
  const where = sp.get(WHERE_PARAM)
  const trimmed = Object.entries(append ?? {}).reduce<Record<string, string>>(
    (trim, [key, value]) => {
      if (value !== undefined) {
        return {
          ...trim,
          [key]: value
        }
      }
      return trim
    },
    {}
  )
  return when || where || Object.keys(trimmed).length
    ? `?${new URLSearchParams({
        ...(trimmed ? trimmed : {}),
        ...(when ? {[WHEN_PARAM]: when} : {}),
        ...(where ? {[WHERE_PARAM]: where} : {})
      })}`
    : ''
}

export const appendQS = (append?: Record<string, string | undefined>) => {
  const sp = new URLSearchParams(document.location.search)
  for (const [key, value] of Object.entries(append ?? {})) {
    if (value !== undefined) {
      sp.set(key, value)
    }
  }
  return sp
}
