import {Dialog} from '@reach/dialog'
import {VisuallyHidden} from '@reach/visually-hidden'
import {ReactNode} from 'react'
import {FaTimes} from 'react-icons/fa'
import styled from '../lib/styled-components'

export const Modal = styled(Dialog)`
  width: 80vw;
  padding: 20px;
  padding-bottom: 30px;
  border-radius: 5px;
  @media (max-width: ${({theme}) => theme.breakPoints.mobile}) {
    width: 90vw;
    padding: 10px;
    padding-bottom: 25px;
  }
`

const Title = styled.h2`
  margin-right: auto;
  font-family: ${({theme}) => theme.font.headerSecondary};
  color: ${({theme}) => theme.colors.blue};
  font-weight: normal;
`

const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: flex-start;
`

const Close = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 24px;
  color: ${({theme}) => theme.colors.blue};
  &:hover {
    color: ${({theme}) => theme.colors.blueLighter};
  }
  &:focus,
  &:active,
  &:focus-visible {
    outline: none;
  }
`

export const ModalHeader = ({
  children,
  onClose
}: {
  children: ReactNode
  onClose: () => void
}) => {
  return (
    <Header>
      <Title>{children}</Title>
      <Close className="close-button" onClick={onClose}>
        <VisuallyHidden>Close</VisuallyHidden>
        <FaTimes />
      </Close>
    </Header>
  )
}
