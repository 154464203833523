import {useEffect, useState} from 'react'
import styled, {keyframes} from '../lib/styled-components'
import {Center} from './Center'

const breathe = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`

const Box = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 30%;
  animation: ${breathe} 4000ms ease-in-out infinite alternate;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  color: ${({theme}) => theme.colors.blue};
`

export const Loading = () => {
  return (
    <Center style={{marginTop: '10px'}}>
      <Box>🍦</Box>
    </Center>
  )
}

export const FullScreenLoading = () => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    const t = setTimeout(() => setShow(true), 800)
    return () => clearTimeout(t)
  }, [])
  return show ? (
    <Center>
      <Box>🍦</Box>
    </Center>
  ) : null
}
