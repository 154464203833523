import styled from '../lib/styled-components'

export const Button = styled.button`
  display: flex;
  font-weight: bold;
  min-width: 100px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border: none;
  letter-spacing: 0.07em;
  gap: 10px;
  text-transform: uppercase;
  color: ${p => p.theme.colors.blue};
  background-color: ${p => p.theme.colors.yellow};
  /* color: ${p => p.theme.colors.white}; */
  border-radius: ${p => p.theme.radius};
  cursor: pointer;
  &:active,
  &:hover {
    background-color: ${p => p.theme.colors.yellowDarker};
  }
  &:disabled {
    background-color: ${p => p.theme.colors.grey};
    color: ${p => p.theme.colors.blueLighter};
    cursor: not-allowed;
  }
  svg {
    font-size: 25px;
    flex-shrink: 0;
  }
`

export const ButtonSecondary = styled(Button)`
  background-color: ${p => p.theme.colors.white};
  border: solid 1px ${p => p.theme.colors.grey};
  &:active,
  &:hover {
    background-color: ${p => p.theme.colors.grey};
  }
`
