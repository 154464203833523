import styled from '../lib/styled-components'

export const IconButton = styled.button`
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 4px;
  font-size: 24px;
  color: ${p => p.theme.colors.blue};
  &:active,
  &:hover {
    color: ${p => p.theme.colors.blueLighter};
  }
`
