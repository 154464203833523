import mapboxgl from 'mapbox-gl'
import type {LocationAccount} from './api'

export const locationsToGeoJson = (
  locations: LocationAccount[]
): GeoJSON.FeatureCollection => {
  const now = new Date()
  const points: GeoJSON.Feature<GeoJSON.Geometry>[] = locations
    .filter(truck => truck.to >= now)
    .map(truck => {
      const {lat, lng, id: locationId, accountId: id} = truck
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lng, lat]
        },
        properties: {locationId, id}
      }
    })

  return {type: 'FeatureCollection', features: points}
}

const size = 450

const loadImage = (map: mapboxgl.Map, id: string, src?: string) =>
  new Promise<string>((resolve, reject) => {
    if (map.hasImage(id)) {
      resolve(id)
    } else if (src) {
      map.loadImage(src, (error, image) => {
        if (error) {
          reject(error)
        } else {
          if (image) {
            // Canvas to image hack required for safari
            // https://stackoverflow.com/questions/29793689/safari-createpattern-fails-with-svgs-suggestions-for-alternatives

            // canvas image source for pattern:
            const imgCanvas = document.createElement('canvas')
            imgCanvas.width = image.width
            imgCanvas.height = image.height

            // draw in the image to canvas
            const imgCtx = imgCanvas.getContext('2d')
            try {
              //@ts-ignore
              imgCtx.drawImage(image, 0, 0, image.width, image.height)
              map.addImage(id, pulsingDot(map, size, imgCanvas))
            } catch (e) {
              console.log('failed to load', src, e)
              map.addImage(id, pulsingDot(map, size))
            }
          }
          resolve(id)
        }
      })
    } else {
      map.addImage(id, pulsingDot(map, size))
      resolve(id)
    }
  })

export const loadImages = (map: mapboxgl.Map, locations: LocationAccount[]) => {
  const unique = locations.reduce<Record<string, string | undefined>>(
    (acc, truck) => {
      if (truck.accountId in acc) {
        return acc
      }
      return {...acc, [truck.accountId]: truck.account.logoSrc ?? undefined}
    },
    {}
  )
  return Promise.all(
    Object.entries(unique).map(([id, src]) => loadImage(map, id, src))
  )
}

const pulsingDot = (
  map: mapboxgl.Map,
  size: number = 200,
  image?: ImageBitmap | HTMLImageElement | HTMLCanvasElement
) => ({
  width: size,
  height: size,
  data: new Uint8Array(size * size * 4),
  pattern: null,

  // When the layer is added to the map,
  // get the rendering context for the map canvas.
  onAdd: function () {
    const canvas = document.createElement('canvas')
    canvas.width = this.width
    canvas.height = this.height
    //@ts-ignore
    this.context = canvas.getContext('2d')
  },

  // Call once before every frame where the icon will be used.
  render: function () {
    const duration = 1000
    const t = (performance.now() % duration) / duration

    const radius = (size / 2) * 0.3
    const outerRadius = (size / 2) * 0.7 * t + radius
    //@ts-ignore
    const context: CanvasRenderingContext2D = this.context

    // Draw the outer circle.
    context.clearRect(0, 0, this.width, this.height)
    context.beginPath()
    context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2)
    // context.fillStyle = `rgba(255, 200, 200, ${1 - t})`
    context.fillStyle = `hsl(46deg 85% 67% / ${1 - t})`
    context.fill()

    // Draw the inner circle.
    context.beginPath()
    context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2)
    context.globalAlpha = 0.8

    if (image) {
      if (!this.pattern) {
        // Todo don't need to do this every frame
        // https://stackoverflow.com/questions/72012056/centering-a-canvas-image-pattern-fill
        //@ts-ignore
        this.pattern = context.createPattern(image, 'no-repeat')
        //@ts-ignore
        this.pattern.setTransform(
          new DOMMatrix([
            // No rotation, 1-1 scale
            1,
            0,
            0,
            1,
            // Translate to center, offset by half-image
            this.width / 2 - image.width / 2,
            this.height / 2 - image.height / 2
          ])
        )
      }
      //@ts-ignore
      context.fillStyle = this.pattern
    } else {
      context.fillStyle = 'hsl(5 100% 52% / .7)'
    }

    context.strokeStyle = 'white'
    context.lineWidth = 2 + 4 * (1 - t)
    context.fill()
    context.stroke()

    // Update this image's data with data from the canvas.
    //@ts-ignore
    this.data = context.getImageData(0, 0, this.width, this.height).data

    // Continuously repaint the map, resulting
    // in the smooth animation of the dot.
    map.triggerRepaint()

    // Return `true` to let the map know that the image was updated.
    return true
  }
})
