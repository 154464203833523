export const theme = {
  font: {
    // header: '"Bungee Spice", sans-serif',
    header: '"Bungee Shade", sans-serif',
    headerSecondary: '"Bungee", sans-serif',
    main: '"Rajdhani", sans-serif'
  },
  colors: {
    // https://coolors.co/ffffff-1a090d-b6e59e-f2d163-313d5a
    white: `hsl(0deg 0% 100% / 1)`,
    grey: `hsl(0deg 0% 98% / 1)`,
    black: `hsl(346deg 49% 7% / 1)`,
    green: `hsl(100deg 58% 76% / 1)`,
    greenDarker: `hsl(100deg 42.5% 52.5% / 1)`,
    red: `hsl(5 100% 52%)`,
    redLighter: `hsl(5 100% 80%)`,
    redLightest: `hsl(5 100% 90%)`,
    yellowLighter: `hsl(46deg 85% 67% / .5)`,
    yellow: `hsl(46deg 85% 67% / 1)`,
    yellowDarker: `hsl(46deg 85% 77% / 1)`,
    blue: `hsl(222deg 29% 27% / 1)`,
    blueLighter: `hsl(222deg 29% 37% / 1)`
  },
  breakPoints: {
    mobile: '550px'
  },
  radius: '5px'
}
export type Theme = typeof theme
