import {isRight} from 'fp-ts/lib/Either'
import {useCallback, useMemo} from 'react'
import {LatLng} from '../common/types'
import {useQueryString} from './useQueryString'
import {tuple} from 'io-ts'
import {NumberFromString} from 'io-ts-types'

const LatLngQSCodec = tuple([NumberFromString, NumberFromString])
export const WHERE_PARAM = 'p'

export const useWhere = () => {
  const [_where, _setWhere] = useQueryString(
    WHERE_PARAM,
    localStorage.getItem(WHERE_PARAM),
    'replace'
  )

  const where = useMemo(() => {
    if (_where) {
      const decode = LatLngQSCodec.decode(_where.split(','))
      if (isRight(decode)) {
        const [lat, lng] = decode.right
        localStorage.setItem(WHERE_PARAM, `${lat},${lng}`)
        return {lat, lng}
      } else {
        console.warn('where decode failed')
      }
    }
  }, [_where])

  const setWhere = useCallback(
    (latLng: LatLng) => {
      _setWhere(`${latLng.lat},${latLng.lng}`)
    },
    [_setWhere]
  )

  return [where, setWhere] as const
}
