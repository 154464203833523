import {useState, useCallback, useEffect, useRef} from 'react'

export const useQueryString = <T = string | undefined>(
  key: string,
  initialValue?: T,
  mode: 'push' | 'replace' = 'push'
) => {
  const initialValueRef = useRef(initialValue)
  const getParamValue = useCallback(() => {
    const sp = new URLSearchParams(document.location.search)
    const val = sp.get(key)
    if (val !== null) {
      return val
    }

    return initialValueRef.current
  }, [key])

  const [value, setValue] = useState(() => {
    return getParamValue()
  })

  const refreshValue = useCallback(() => {
    setValue(getParamValue())
  }, [getParamValue])

  const onSetValue = useCallback(
    (newValue: T) => {
      setValue(newValue)
      const sp = new URLSearchParams(document.location.search)
      if (newValue === undefined) {
        sp.delete(key)
      } else {
        sp.set(
          key,
          newValue instanceof Date ? newValue.toISOString() : String(newValue)
        )
      }
      window.history[mode === 'push' ? 'pushState' : 'replaceState'](
        null,
        '',
        `${document.location.pathname}?${sp}`
      )
    },
    [key, mode]
  )

  useEffect(() => {
    window.addEventListener('popstate', refreshValue)
    return () => window.removeEventListener('popstate', refreshValue)
  }, [refreshValue])

  useEffect(() => {
    refreshValue()
  })

  return [value as T, onSetValue] as const
}
