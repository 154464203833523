import type {GeocoderOptions} from '@mapbox/mapbox-gl-geocoder'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import type {LngLatLike, MapboxOptions} from 'mapbox-gl'
import mapboxgl from 'mapbox-gl'
import {useCallback, useRef} from 'react'

const REACT_APP_MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN
if (typeof REACT_APP_MAPBOX_TOKEN !== 'string') {
  throw new Error('Mapbox token not found')
}

mapboxgl.accessToken = REACT_APP_MAPBOX_TOKEN
mapboxgl.workerCount = 2
mapboxgl.prewarm()

const DEFAULT_CENTER: LngLatLike = [170.5006, -45.8795]

export const useMap = () => {
  const mapRef = useRef<mapboxgl.Map>()
  const initMap = useCallback(
    (container: HTMLElement, options?: Partial<MapboxOptions>) => {
      mapRef.current = new mapboxgl.Map({
        style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
        zoom: 3,
        ...(options ? options : {}),
        center: options?.center ?? DEFAULT_CENTER, //Todo need to get from nginx I guess
        container
      })
      return mapRef.current
    },
    []
  )

  const getStatic = useCallback(
    (
      container: HTMLElement,
      {center, zoom}: {center?: [number, number] | undefined; zoom: number}
    ) => {
      return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${(
        center ?? DEFAULT_CENTER
      ).join()},${zoom}/${container.offsetWidth}x${
        container.offsetHeight
      }?access_token=${REACT_APP_MAPBOX_TOKEN}`
    },
    []
  )

  return [
    initMap,
    {
      mapRef: mapRef.current,
      getStatic
    }
  ] as const
}

export const useMapWithGeocoder = () => {
  const [initMap, {mapRef}] = useMap()
  const geoCoderRef = useRef<MapboxGeocoder>()

  const initGeoCoderMap = useCallback(
    (
      container: HTMLElement,
      options?: Partial<MapboxOptions>,
      geoCoderOptions?: Partial<GeocoderOptions>
    ) => {
      const map = initMap(container, options)
      geoCoderRef.current = new MapboxGeocoder({
        ...(geoCoderOptions ? geoCoderOptions : {}),
        accessToken: mapboxgl.accessToken,
        //@ts-ignore
        mapboxgl: mapboxgl
      })
      map.addControl(geoCoderRef.current)
      return [map, geoCoderRef.current] as const
    },
    [initMap]
  )

  return [initGeoCoderMap, {mapRef, geoCoderRef}] as const
}
