import {useEffect} from 'react'
import {useNavigate} from 'react-router'
import {FullScreenLoading} from '../components/Loading'
import {WaitThenNavigate} from '../components/WaitThenNavigate'
import {useValidateEmailLogin} from '../hooks/useValidateEmailLogin'

export const Auth = ({reloadAuth}: {reloadAuth: () => Promise<void>}) => {
  const [authState, {loading}] = useValidateEmailLogin()
  const navigate = useNavigate()

  useEffect(() => {
    if (authState && authState.valid) {
      reloadAuth().then(() => {
        navigate('/account')
      })
    }
  }, [authState, navigate, reloadAuth])

  if (loading) {
    return <FullScreenLoading />
  }

  if (!authState?.valid) {
    return <WaitThenNavigate to="/login?error=token" />
  }

  return null
}
