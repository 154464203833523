import {Location} from '../common/types'
import {WHEN_PARAM} from '../hooks/useWhen'
import {WHERE_PARAM} from '../hooks/useWhere'
import {withQS} from './qs'

export const truckUrl = ({
  id,
  locationId
}: {
  id: string
  locationId?: string
}) => {
  return `/t/${id}${withQS({l: locationId})}`
}

export const locationURL = (location: Location) => {
  return `/?${new URLSearchParams({
    [WHEN_PARAM]: location.from.toISOString(),
    [WHERE_PARAM]: location.lat + ',' + location.lng
  })}`
}
