import {useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import type {AuthRes} from '../lib/api'
import {Handler, post, subscribe, unSubscribe} from '../workers/worker'

export const useValidateEmailLogin = () => {
  const [urlSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [authState, setAuthState] = useState<AuthRes>()

  useEffect(() => {
    const handler: Handler = res => {
      if (res.action === 'auth') {
        setAuthState(res.data)
        setLoading(false)
      }
    }
    subscribe(handler)
    return () => unSubscribe(handler)
  }, [])

  useEffect(() => {
    const token = urlSearchParams.get('token')
    if (token) {
      post({action: 'email-auth', token})
    } else {
      setLoading(false)
    }
  }, [urlSearchParams])

  return [authState, {loading}] as const
}
