import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import getHours from 'date-fns/getHours'
import isValid from 'date-fns/isValid'
import max from 'date-fns/max'
import parseISO from 'date-fns/parseISO'
import {useCallback, useMemo} from 'react'
import {useQueryString} from './useQueryString'

export const WHEN_PARAM = 'when'
const now = new Date()

const dayName = (add: number): [string, Date] => {
  const date = addDays(now, add)
  return [format(date, 'EEEE'), date]
}

export const getWhenOptions = (): [string, Date][] => [
  [getHours(now) > 16 ? 'Tonight' : 'Today', now],
  ['Tomorrow', addDays(now, 1)],
  dayName(2),
  dayName(3),
  dayName(4),
  dayName(5),
  dayName(6),
  dayName(7)
]

export const useWhen = () => {
  const [_when, _setWhen] = useQueryString(WHEN_PARAM, now.toISOString())

  const when = useMemo(() => {
    const v = parseISO(_when)
    return isValid(v) ? max([v, now]) : now
  }, [_when])

  const setWhen = useCallback(
    (date: Date) => {
      _setWhen(date.toISOString())
    },
    [_setWhen]
  )

  return [when, setWhen] as const
}
