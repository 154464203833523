import '@reach/dialog/styles.css'
import React, {useState} from 'react'
import {FaUserLock} from 'react-icons/fa'
import styled from '../lib/styled-components'
import {IconButton} from './IconButton'
import {Modal, ModalHeader} from './Modal'
import {Register} from './Register'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
`
export const EmailSent = styled.div`
  p {
    margin-bottom: 30px;
  }
`

export const AccountMenu = () => {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <IconButton onClick={() => setOpen(true)}>
        <FaUserLock />
      </IconButton>
      <Modal isOpen={open} onDismiss={() => setOpen(false)}>
        <ModalHeader onClose={() => setOpen(false)}>
          Register your food truck
        </ModalHeader>
        <Register />
      </Modal>
    </div>
  )
}
