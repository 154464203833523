import {useCallback, useEffect, useState} from 'react'
import type {Accounts} from '../common/types'
import {Handler, post, subscribe, unSubscribe} from '../workers/worker'

export const useAccounts = () => {
  const [accounts, setAccounts] = useState<Accounts[]>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()

  useEffect(() => {
    const handler: Handler = res => {
      switch (res.action) {
        case 'accounts': {
          setAccounts(res.data)
          setLoading(false)
          break
        }
        case 'error': {
          setError(res.data)
          // error could be unrelated to accounts?
          setLoading(false)
        }
      }
    }
    subscribe(handler)
    return () => unSubscribe(handler)
  }, [])

  const fetchAccounts = useCallback(() => {
    post({action: 'get-accounts'})
  }, [])

  useEffect(() => {
    fetchAccounts()
  }, [fetchAccounts])

  return [accounts, {loading, refetch: fetchAccounts, error}] as const
}
