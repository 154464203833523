import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import endOfDay from 'date-fns/endOfDay'
import {boolean} from 'io-ts'
import mapboxgl from 'mapbox-gl'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {FaCog, FaCompass, FaQuestionCircle, FaSearch} from 'react-icons/fa'
import {Route, Routes, useNavigate} from 'react-router'
import {useSearchParams} from 'react-router-dom'
import type {Account} from '../../common/types'
import {AccountMenu} from '../../components/AccountMenu'
import {Header} from '../../components/Header'
import {IconButton} from '../../components/IconButton'
import {LinkButton} from '../../components/LinkButton'
import {useMap} from '../../hooks/useMap'
import {usePersistedState} from '../../hooks/usePersistedState'
import {usePosition} from '../../hooks/usePosition'
import {useWhen} from '../../hooks/useWhen'
import {useWhere, WHERE_PARAM} from '../../hooks/useWhere'
import {Layout} from '../../layouts/Layout'
import type {LocationAccount} from '../../lib/api'
import {loadImages, locationsToGeoJson} from '../../lib/map'
import {appendQS, withQS} from '../../lib/qs'
import styled from '../../lib/styled-components'
import {truckUrl} from '../../lib/urls'
import {Handler, post, subscribe, unSubscribe} from '../../workers/worker'
import {DaySelect} from './DaySelect'
import {WelcomeModal} from './WelcomeModal'

const AccountLocationsModal = React.lazy(
  () => import('./AccountLocationsModal')
)
const LoginModal = React.lazy(() => import('../../components/LoginModal'))
const TruckModal = React.lazy(() => import('./TruckModal'))

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
`
const StaticMap = styled.div`
  width: 100%;
  height: 100%;
  display: none;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 24px;
  color: ${({theme}) => theme.colors.blue};
  background-color: ${props => props.theme.colors.white};
  input {
    color: ${({theme}) => theme.colors.blue};
  }
`

export const Home = ({account}: {account?: Account}) => {
  // mapbox helper
  const [initMap, {getStatic}] = useMap()
  // user position helper
  const [initPosition, {latLng: position}] = usePosition()
  // store a reference to mapbox instance
  const mapRef = useRef<mapboxgl.Map>()
  // store the center of the map
  const [latLng, setLatLng] = useWhere()
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()
  const [locations, setLocations] = useState<LocationAccount[]>()
  const [mapReady, setMapReady] = useState(false)
  const [firstVisit, setFirstVisit] = usePersistedState(
    true,
    'welcome',
    boolean
  )
  const [showHelp, setShowHelp] = useState(false)
  // store the current time that we are requesting trucks for
  const [when, setWhen] = useWhen()

  // Use the users position to set the map center
  useEffect(() => {
    if (position) {
      if (mapRef.current) {
        if (mapRef.current.getZoom() < 14) {
          mapRef.current.zoomTo(14)
        }
        mapRef.current.setCenter(position)
      }
      setLatLng(position)
    }
  }, [position, setLatLng])

  useEffect(() => {
    let t: NodeJS.Timeout
    if (firstVisit) {
      t = setTimeout(initPosition, 3000)
      setFirstVisit(false)
    }
    return () => {
      if (t) {
        clearTimeout(t)
      }
    }
  }, [firstVisit, initPosition, setFirstVisit])

  useEffect(() => {
    if (latLng && mapRef.current && mapReady) {
      mapRef.current.setCenter(latLng)
    }
  }, [latLng, mapReady])

  useEffect(() => {
    const refresh = differenceInMilliseconds(endOfDay(when), when)
    const timeout = setTimeout(() => {
      setWhen(endOfDay(when))
    }, refresh)
    return () => clearTimeout(timeout)
  }, [when, setWhen])

  // Render trucks as markers on the map
  useEffect(() => {
    if (locations && mapRef.current && mapReady) {
      const geoJson = locationsToGeoJson(locations)
      loadImages(mapRef.current, locations)
      if (!mapRef.current.getLayer('trucks')) {
        //creating layer
        mapRef.current.addSource('trucks', {
          type: 'geojson',
          data: geoJson
        })
        mapRef.current.addLayer({
          id: 'trucks',
          type: 'symbol',
          source: 'trucks',
          layout: {
            'icon-image': ['get', 'id'],
            'icon-size': 0.25,
            'icon-allow-overlap': true,
            'text-allow-overlap': true
          }
        })
      } else if (mapRef.current.getSource('trucks')) {
        // updating layer
        //@ts-ignore
        mapRef.current.getSource('trucks')?.setData(geoJson)
      }
    }
  }, [locations, mapReady])

  useEffect(() => {
    const handler: Handler = res => {
      switch (res.action) {
        case 'locations':
          setLocations(res.data)
      }
    }
    subscribe(handler)
    return () => unSubscribe(handler)
  }, [])

  // Refetch map markers when the maps center is changed
  useEffect(() => {
    if (latLng) {
      const bounds = mapRef.current?.getBounds()
      if (bounds) {
        post({
          action: 'get-locations',
          data: {
            latLng,
            when,
            bounds: bounds.toArray().flat()
          }
        })
      }
    }
  }, [latLng, when])

  // Initialize the map, add add event listeners
  const initMapRef = useCallback(
    (el: HTMLElement | null) => {
      setMapReady(false)
      if (el) {
        // This can be stale, that's ok, I only care about its value on init
        const center: [number, number] | undefined = latLng
          ? [latLng.lng, latLng.lat]
          : undefined
        const zoom = 12
        const map = initMap(el, {zoom, center})

        let loaded: boolean = false
        let staticMap: HTMLElement

        map.addControl(new mapboxgl.NavigationControl(), 'top-left')
        mapRef.current = map
        map.on('moveend', e => {
          const {lat, lng} = map.getCenter()
          setLatLng({lat, lng})
        })
        map.on('load', e => {
          loaded = true
          staticMap.style.display = 'none'
          setMapReady(true)
          const {lat, lng} = map.getCenter()
          setSearchParams(appendQS({[WHERE_PARAM]: `${lat},${lng}`}), {
            replace: true
          })
        })
        map.on('click', 'trucks', e => {
          const properties = e.features?.[0]?.properties
          if (properties) {
            if (properties.id && properties.locationId) {
              // setTruckId(decode.right.id)
              navigate(
                truckUrl({id: properties.id, locationId: properties.locationId})
              )
            } else {
              console.warn(`Unexpected marker shape`, properties)
            }
          }
        })
        map.on('mouseover', 'trucks', e => {
          map.getCanvas().style.cursor = 'pointer'
        })
        map.on('mouseleave', 'trucks', e => {
          map.getCanvas().style.cursor = ''
        })

        staticMap = document.getElementById('static-map')!
        const staticUrl = getStatic(el, {
          zoom,
          center
        })
        if (!loaded) {
          staticMap.style.backgroundImage = `url(${staticUrl})`
          staticMap.style.display = 'block'
        }
      }
    },
    // navigate is new each render, RR is crap https://github.com/remix-run/react-router/issues/7634#issuecomment-1006997643
    // eslint-disable-next-line react-hooks/exhaustive-deps,
    [initMap]
  )

  const showSchedule = () => {
    if (!latLng) {
      initPosition({
        onPosition: () => navigate(`/schedule${withQS()}`)
      })
    } else {
      navigate(`/schedule${withQS()}`)
    }
  }

  return (
    <Layout
      header={
        <Header
          menuLeft={
            <IconButton onClick={() => setShowHelp(true)} title="Help">
              <FaQuestionCircle />
            </IconButton>
          }
          menuRight={
            account ? (
              <LinkButton to="/account">
                <FaCog />
              </LinkButton>
            ) : (
              <AccountMenu />
            )
          }
        />
      }
      footer={
        <Footer>
          <IconButton onClick={showSchedule} title="Show all trucks">
            <FaSearch />
          </IconButton>
          <DaySelect when={when} setWhen={setWhen} />
          <IconButton onClick={() => initPosition()} title="Use my location">
            <FaCompass />
          </IconButton>
        </Footer>
      }
    >
      <StaticMap id="static-map" />
      <MapWrapper ref={initMapRef}></MapWrapper>
      {showHelp && (
        <WelcomeModal
          onClose={() => setShowHelp(false)}
          onLocation={() => initPosition()}
          onSearch={showSchedule}
          haveLocations={!!locations?.length}
          when={when}
          setWhen={setWhen}
        />
      )}
      <Routes>
        <Route
          path="login"
          element={
            <React.Suspense>
              <LoginModal show={true} onClose={() => navigate('/')} />
            </React.Suspense>
          }
        />
        <Route
          path="register"
          element={
            <React.Suspense>
              <LoginModal
                register={true}
                show={true}
                onClose={() => navigate('/')}
              />
            </React.Suspense>
          }
        />
        {!!latLng && (
          <Route
            path="schedule"
            element={
              <React.Suspense>
                <AccountLocationsModal
                  title="Find a truck"
                  latLng={latLng}
                  onClose={() => navigate(`/${withQS()}`)}
                />
              </React.Suspense>
            }
          />
        )}
        <Route
          path="t/:id"
          element={
            <React.Suspense>
              <TruckModal onClose={() => navigate(`/${withQS()}`)} />
            </React.Suspense>
          }
        />
      </Routes>
    </Layout>
  )
}
