import {useCallback, useMemo, useState} from 'react'

type Settings = {
  watch: boolean
  enableHighAccuracy: boolean
  timeout: number
  maximumAge: number
  decimalPlaces: number
  onPosition?: (latLng: LatLng, position: GeolocationPosition) => void
}

const defaultSettings: Settings = {
  watch: false,
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
  // should give us accuracy to a meter, that's all we need for a truck
  // http://wiki.gis.com/wiki/index.php/Decimal_degrees
  decimalPlaces: 5
}

type LatLng = {lat: number; lng: number}

export function usePosition() {
  const [latLng, setLatLng] = useState<LatLng | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  const onError = (error: Error | GeolocationPositionError) => {
    setError(error.message)
    setLoading(false)
  }

  const init = useCallback((settings: Partial<Settings> = defaultSettings) => {
    setLoading(true)
    if (!navigator || !navigator.geolocation) {
      setError('Geolocation is not supported')
      return
    }
    const {watch, decimalPlaces, onPosition, ...geoSettings} = settings
    const onChange = (position: GeolocationPosition) => {
      const {latitude, longitude} = position.coords
      const lat = decimalPlaces
        ? parseFloat(latitude.toFixed(decimalPlaces))
        : latitude
      const lng = decimalPlaces
        ? parseFloat(longitude.toFixed(decimalPlaces))
        : longitude
      // Creating a fresh object here, so need to memo this before return
      setLatLng(s => ({...s, lat, lng}))
      if (onPosition) {
        onPosition({lat, lng}, position)
      }
      setLoading(false)
    }

    let watcher: number | null = null
    if (watch) {
      watcher = navigator.geolocation.watchPosition(
        onChange,
        onError,
        geoSettings
      )
    } else {
      navigator.geolocation.getCurrentPosition(onChange, onError, geoSettings)
    }

    return () => {
      watcher && navigator.geolocation.clearWatch(watcher)
    }
  }, [])

  const lat = latLng?.lat
  const lng = latLng?.lng

  // only return a new object if lat or lng has changed
  const latLngMemo: LatLng | undefined = useMemo(() => {
    return lat && lng ? {lat, lng} : undefined
  }, [lat, lng])

  return [
    init,
    {
      latLng: latLngMemo,
      lat,
      lng,
      loading,
      error
    }
  ] as const
}
