import {useCallback, useEffect, useState} from 'react'
import type {Account} from '../common/types'
import {Handler, post, subscribe, unSubscribe} from '../workers/worker'
import {useAccounts} from './useAccounts'

export const useAccount = () => {
  const [account, setAccount] = useState<Account>()
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState(true)
  const [
    accounts,
    {loading: loadingAccounts, refetch: refetchAccounts, error: accountsError}
  ] = useAccounts()
  const [accountId, setAccountId] = useState<string>()

  // Todo split this out into a separate hook
  useEffect(() => {
    if (accountId) {
      const handler: Handler = res => {
        if (res.action === 'account' && res.data.id === accountId) {
          setAccount(res.data)
          setLoading(false)
        }
      }
      subscribe(handler)
      return () => unSubscribe(handler)
    }
  }, [accountId])

  const fetchAccount = useCallback(() => {
    if (accountId) {
      post({action: 'get-account', data: {id: accountId}})
    }
  }, [accountId])

  useEffect(() => {
    fetchAccount()
  }, [fetchAccount])

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      // For now we just pick the first account
      // This will be selectable at some point via setAccountId
      setAccountId(accounts[0].id)
    }
    if (!loadingAccounts && !accounts) {
      setLoading(false)
    }
    if (accountsError) {
      setError(accountsError)
    }
  }, [accounts, loadingAccounts, accountsError])

  const refetch = useCallback(async () => {
    await refetchAccounts()
    await fetchAccount()
  }, [fetchAccount, refetchAccounts])

  return [account, {error, refetch, loading, accounts, setAccountId}] as const
}
