// see https://www.styled-components.com/docs/api#define-a-theme-interface

import * as styledComponents from 'styled-components'
import {Theme} from '../theme'

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  useTheme
  // @ts-ignore
} = styledComponents as styledComponents.ThemedStyledComponentsModule<Theme>

export {css, createGlobalStyle, keyframes, ThemeProvider, useTheme}
export default styled
