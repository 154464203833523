import {useEffect, useState} from 'react'
import {Navigate} from 'react-router'

export const WaitThenNavigate = ({to}: {to: string}) => {
  const [navigate, setNavigate] = useState(false)
  useEffect(() => {
    const t = setTimeout(() => {
      setNavigate(true)
    }, 1500)
    return () => {
      clearTimeout(t)
    }
  })

  return navigate ? <Navigate to={to} /> : null
}
