import {useState} from 'react'
import {FaCompass} from 'react-icons/fa'
import {Button} from '../../components/Button'
import {IconButton} from '../../components/IconButton'
import {Modal, ModalHeader} from '../../components/Modal'
import styled from '../../lib/styled-components'
import {DaySelect} from './DaySelect'

const ButtonWrapper = styled.div<{move?: boolean}>`
  display: flex;
  justify-content: center;
  svg {
    font-size: 35px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const WelcomeModal = ({
  onClose,
  onLocation,
  onSearch,
  haveLocations,
  when,
  setWhen
}: {
  onClose: () => void
  onLocation: () => void
  onSearch: () => void
  when: Date
  setWhen: (date: Date) => void
  haveLocations: boolean
}) => {
  const [step, setStep] = useState<'location' | 'days'>('location')
  return (
    <Modal onDismiss={onClose}>
      <ModalHeader onClose={onClose}>Find a truck</ModalHeader>
      <Wrapper>
        {step === 'location' && (
          <>
            Click the compass to jump to your current location.
            <ButtonWrapper>
              <IconButton
                onClick={() => {
                  onLocation()
                  setStep('days')
                }}
              >
                <FaCompass />
              </IconButton>
            </ButtonWrapper>
          </>
        )}
        {step === 'days' && (
          <>
            <p>Nice! When do you want to eat?</p>
            <ButtonWrapper>
              <DaySelect
                when={when}
                setWhen={setWhen}
                onClick={() => {
                  if (haveLocations) {
                    onClose()
                  }
                }}
              />
            </ButtonWrapper>
            <Button onClick={onClose}>Lets Go</Button>
            {/* {!haveLocations && (
            <>
              <p style={{marginTop: '30px'}}>
                Or click the magnifying glass to see a list of all trucks
                nearby.
              </p>
              <ButtonWrapper>
                <IconButton onClick={onSearch}>
                  <FaSearch />
                </IconButton>
              </ButtonWrapper>
            </>
          )} */}
          </>
        )}
      </Wrapper>
    </Modal>
  )
}
